import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Img from 'gatsby-image';
import Layout from '../components/shared/Layout';
import SecondaryPageWrapper from '../components/styled/SecondaryPageWrapper';
import TopBanner from '../components/shared/TopBanner';
import Container from '../components/styled/Container';
import { CopyHeading } from '../components/styled/Typography';
// import PartnerContact from '../components/shared/PartnerContact';
import { viewports } from '../utils/variables';

const PartnersPage = ({ data }) => {
  const {
    wordpressPage: { acf }
  } = data;
  return (
    <Layout theme="secondary" slug="partners">
      <SecondaryPageWrapper>
        <TopBanner heading={acf.banner_heading} image={acf.banner_image} />
        <Container style={containerCSS}>
          <CopyHeading>{acf.partners_heading}</CopyHeading>
          <div css={partnersCSS}>
            {acf.partners_partners.map(partner => {
              const fluid = partner.logo.localFile.childImageSharp.fluid;
              return (
                <div css={logoWrapperCSS} key={fluid}>
                  <Img fluid={fluid} css={logoCSS} />
                </div>
              );
            })}
          </div>
          {/* <PartnerContact
            heading={acf.contact_heading}
            copy={acf.contact_copy}
          /> */}
        </Container>
      </SecondaryPageWrapper>
    </Layout>
  );
};

export default memo(PartnersPage);

const containerCSS = css`
  padding: 3.125rem 0 5rem;
  text-align: center;
`;

const partnersCSS = css`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 1.375rem 0 2.375rem;

  @media ${viewports.medium} {
    width: 75%;
  }
`;

const logoWrapperCSS = css`
  padding: 0 1.5rem;
  width: 50%;

  @media ${viewports.medium} {
    padding: 0 5%;
    width: 33.33%;
  }
`;

const logoCSS = css`
  height: auto;
  width: 100%;
`;

export const query = graphql`
  query partnersQuery($page_id: Int = 71) {
    wordpressPage(wordpress_id: { eq: $page_id }) {
      title
      acf {
        banner_heading
        banner_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        partners_heading
        partners_partners {
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        contact_heading
        contact_copy
      }
    }
  }
`;
